@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    h1 {
        @apply mx-auto;
        @apply font-poppins;
        @apply font-semibold;
        @apply text-6xl;
        @apply font-medium;
        @apply leading-tight;
        @apply tracking-tight;
        @apply text-white;
        @apply max-sm:text-4xl;
    }
    h2 {
        @apply text-2xl;
        @apply font-poppins;
        @apply font-medium;
        @apply text-center;
        @apply text-white;
        @apply max-sm:text-xl;
    }
    h3 {
        @apply font-poppins;
        @apply font-medium;
        @apply text-xl;
        @apply text-left;
        @apply text-white;
    }
    h4 {
        @apply text-base;
        @apply font-poppins;
        @apply font-semibold;
        @apply text-left;
        @apply text-lightGray;
    }
    p {
        @apply font-poppins;
        @apply font-normal;
        @apply text-base;
        @apply leading-relaxed;
        @apply text-white;
        @apply text-left;
    }
    a {
        @apply text-sm;
        @apply font-poppins;
        @apply font-normal;
        @apply text-white;
        @apply cursor-pointer;
    }
    a:hover {
        @apply text-white/80;
    }
    .wrapper {
        @apply py-24;
        @apply bg-black;
        @apply flex;
        @apply flex-col;
        @apply justify-center;
        @apply items-center;
        @apply relative;
        @apply gap-24;
    }
    button {
        @apply bg-gradient-to-r from-purple via-magenta to-yellow/90;
        @apply flex;
        @apply justify-center;
        @apply items-center;
        @apply relative;
        @apply overflow-hidden;
        @apply gap-4;
        @apply px-6;
        @apply py-3;
        @apply rounded-lg;
        @apply font-poppins;
        @apply font-medium;
        @apply text-white;
        @apply text-sm;
        @apply hover:from-primary;
        @apply hover:via-primary;
        @apply hover:to-primary;
    }
    button:hover {
        @apply bg-primary;
    }
}
